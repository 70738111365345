import React, { useState, useEffect } from 'react'
import image2 from '../images/logo-moz.png'
import { Link } from 'react-scroll';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isNavbarFixed, setIsNavbarFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector('header');
            const fixedNav = header.offsetTop;

            if (window.pageYOffset > fixedNav) {
                setIsNavbarFixed(true);
            } else {
                setIsNavbarFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`bg-transparent ${isNavbarFixed ? 'navbar-fixed' : ''} fixed top-0 w-full flex items-center z-10`}>
            <div className="container">
                <div className="flex items-center justify-between relative">
                    <div className="px-4">
                        <a href="/" className="font-bold text-lg text-primary block py-6"><img src={image2} alt='logo-fmg' className='w-15 h-10'/></a>
                    </div>
                    <div className="flex items-center">
                        <button id="hamburger" name="hamburger" type="button" className={`block absolute right-4 lg:hidden ${isMenuOpen ? 'hamburger-active' : 'hamburger'}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <span className="hamburger-line transition duration-300 ease-in-out origin-top-left"></span>
                            <span className="hamburger-line transition duration-300 ease-in-out"></span>
                            <span className="hamburger-line transition duration-300 ease-in-out origin-bottom-left"></span>
                        </button>
                        <nav id="nav-menu" className={`${isMenuOpen ? 'block' : 'hidden'} absolute py-5 bg-dark shadow-lg rounded-lg max-w-[250px] w-full right-4 top-full lg:block lg:static lg:bg-transparent lg:max-w-full lg:shadow-none lg:rounded-none`}>
                            <ul className="block lg:flex">
                                <li className="group">
                                    <Link to='home' spy={true} smooth={true} duration={500} className="text-base text-white py-2 mx-8 flex  group-hover:text-primary cursor-pointer">Home</Link>
                                </li>
                                <li className="group">
                                    <Link to='about' spy={true} smooth={true} duration={500} className="text-base text-white py-2 mx-8 flex  group-hover:text-primary cursor-pointer">About</Link>
                                </li>
                                <li className="group">
                                    <Link to='service' spy={true} smooth={true} duration={500} className="text-base text-white py-2 mx-8 flex group-hover:text-primary cursor-pointer">Services</Link>
                                </li>
                                <li className="group">
                                    <Link to='client' spy={true} smooth={true} duration={500} className="text-base text-white py-2 mx-8 flex group-hover:text-primary cursor-pointer">Clients</Link>
                                </li>
                                <li className="group">
                                    <Link to='contact' spy={true} smooth={true} duration={500} className="text-base text-white py-2 mx-8 flex group-hover:text-primary cursor-pointer">Contact</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Navbar