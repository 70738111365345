import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from 'react-loading'

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const form = useRef();

    const formIsValid = () => {
        const inputs = form.current.getElementsByTagName('input');

        let isValid = true;

        for(let i = 0; i < inputs.length; i++) {
            if (inputs[i].hasAttribute('required') && !inputs[i].value) {
                isValid = false;
                break;
            }
        }
        return isValid;
    }

    const resetForm = () => {
        setName('');
        setEmail('');
        setMessage('');
    }

    const sendEmail = (e) => {
        e.preventDefault();

        if (!formIsValid()) {
            return;
        }

        setIsLoading(true);

        const serviceId = 'service_3c9zeoi';
        const templateId = 'template_of52vih';
        const userId = 'A_W1aPiNdy0UelY1R';

        emailjs.sendForm(serviceId, templateId, e.target, userId)
            .then((result) => {
                console.log(result.text);
                toast.success('Pesan Berhasil Terkirim', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
                resetForm();    
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <section id="contact" className="pt-20 pb-20">
        <div className="container">
            <div className="w-full px-4">
                <div className="mx-auto text-center mb-16">
                    <h4 className="font-semibold text-lg text-primary mb-2 uppercase">Contact Us</h4>
                    <h2 className="font-bold text-dark text-3xl mb-4 sm:text-4xl lg:text-4xl">Hubungi Lebih Lanjut</h2>
                </div>
            </div>
            <form action="" ref={form} onSubmit={sendEmail}>
                <div className="w-full lg:w-2/3 lg:mx-auto">
                    <div className="w-full px-4 mb-8">
                        <label for="name" className="text-base font-bold text-primary">Nama</label>
                        <input type="text" id="name" name='name' required value={name} onChange={(e) => setName(e.target.value)} className="w-full bg-slate-200 text-dark p-3 rounded-md focus:outline-none focus:ring-primary focus:ring-1 focus:border-primary"/>
                    </div>
                    <div className="w-full px-4 mb-8">
                        <label for="email" className="text-base font-bold text-primary">Email</label>
                        <input type="text" id="email" name='email' required value={email} onChange={(e) => setEmail(e.target.value)} className="w-full bg-slate-200 text-dark p-3 rounded-md focus:outline-none focus:ring-primary focus:ring-1 focus:border-primary"/>
                    </div>
                    <div className="w-full px-4 mb-8">
                        <label for="message" className="text-base font-bold text-primary">Message</label>
                        <textarea type="text" id="message" name='message' required value={message} onChange={(e) => setMessage(e.target.value)} className="w-full bg-slate-200 text-dark p-3 rounded-md focus:outline-none focus:ring-primary focus:ring-1 focus:border-primary h-32"></textarea>
                    </div>
                    <div className="w-full px-4">
                        <button type='submit' className="text-base font-semibold text-white bg-primary py-3 px-8 rounded-full w-full transition hover:opacity-80 hover:shadow-lg duration-500" disabled={isLoading}>
                            {isLoading ? 
                            <div className='flex justify-center items-center'>
                                <Loading type="spin" className='flex items-center' color="#fff" height={25} width={25}/>
                            </div>
                            : 'Send'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <ToastContainer/>
    </section>
    )
}

export default Contact