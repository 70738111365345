import React from 'react'
import kemenag from '../images/kemenag.png'
import baznas from '../images/baznas.png'
import esdm from '../images/esdm.png'
import pendidikan from '../images/pendidikan.png'

const client = () => {
    return (
        <section id="client" className="pt-20 pb-20 bg-red-800">
        <div className="container">
            <div className="w-full px-4">
                <div className="mx-auto text-center mb-16">
                    <h4 className="font-semibold text-lg text-white mb-2 uppercase">Our Clients</h4>
                    <h2 className="font-bold text-white text-3xl mb-4 sm:text-4xl lg:text-4xl">Mitra Kerjasama</h2>
                </div>
            </div>
            <div className="w-full px-4 flex flex-wrap items-center justify-center">
                <div className="flex">
                    <a href="/" className="max-w-[120px] mx-4 py-4 grayscale opacity-60 transition hover:grayscale-0 hover:opacity-100 lg:mx-6 xl:mx-8">
                        <img src={kemenag} alt="kemenag"/>
                    </a>
                </div>
                <div className="flex">
                    <a href="/" className="max-w-[120px] mx-4 py-4 grayscale opacity-60 transition hover:grayscale-0 hover:opacity-100 lg:mx-6 xl:mx-8">
                        <img src={esdm} alt="esdm"/>
                    </a>
                </div>
                <div className="flex">
                    <a href="/" className="max-w-[120px] mx-4 py-4 grayscale opacity-60 transition hover:grayscale-0 hover:opacity-100 lg:mx-6 xl:mx-8">
                        <img src={pendidikan} alt="pendidikan"/>
                    </a>
                </div>
                <div className="flex">
                    <a href="/" className="max-w-[120px] mx-4 py-4 grayscale opacity-60 transition hover:grayscale-0 hover:opacity-100 lg:mx-6 xl:mx-8">
                        <img src={baznas} alt="baznas"/>
                    </a>
                </div>
            </div>
        </div>
    </section>
    )
}

export default client