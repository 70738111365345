import React from 'react'
import image1 from '../images/background-hero.jpg'

const Hero = () => {
    return (
            <div id='home' className='w-full h-screen'>
                <img src={image1} alt="bg" className='top-0 left-0 w-full h-screen object-cover'/>
                <div className='bg-black/60 absolute top-0 left-0 w-full h-screen'/>
                <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    <div className="container text-center">
                        <h1 className="text-lg font-semibold uppercase text-primary md:text-xl">Company Profile
                        <span className="block font-bold text-2xl my-2 capitalize text-white lg:text-5xl lg:my-5">WE ARE MOZ INDONESIA</span>
                        </h1>
                        <h2 className="font-medium text-white text-lg mb-3 lg:text-2xl">
                            PT. MOZ Kreasindo Baraka
                        </h2>
                        <p className="font-medium text-slate-100 mb-10 leading-relaxed text-xl">
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas molestiae tenetur ratione excepturi, alias id in perspiciatis pariatur quasi corrupti sit illum natus amet omnis?
                        </p>
                        <a href="#contact" className="text-base font-bold bg-primary text-white py-3 px-8 rounded-full hover:shadow-lg hover:opacity-80 transition duration-300 ease-in-out">
                            Contact Us
                            </a>
                    </div>
                </div>
            </div>    
            
    )
}

export default Hero
