import React from 'react'
import image1 from "../images/about-img.png"

const about = () => {
    return (
    <section id='about' className="pt-20 pb-20 bg-red-800">
        <div className="container">
            <div className="flex flex-wrap">
                <div className="w-ful px-4 mb-10 lg:w-1/2">
                    <h4 className="font-bold uppercase text-white text-lg mb-3">About Us</h4>
                    <h2 className="font-bold text-white text-3xl mb-5 max-w-lg lg:text-4xl">Ringkasan Korporasi</h2>
                    <p className="font-medium text-base text-white text-justify max-w-xl lg:text-lg">PT. MOZ Kreasindo Baraka atau MOZ didirikan pada tahun 2024 dan akan terus berkembang pesat. MOZ adalah perusahaan Digital Multichannel Management yang saat ini berkedudukan di Kota Bogor dan memiliki keahlian di bidang Event Organizer, Business Support, Customer Service serta IT Consultant.</p>
                </div>
                <div className="w-full px-4 text-center lg:w-1/2">
                    <img src={image1} alt="about" />
                </div>
            </div>
        </div>
    </section>
    )
}

export default about